import React, { useEffect } from "react"
import PageRoot from "../../components/PageRoot"
import { Link } from "gatsby"
import { Button } from "antd"
import ContainerWithPadding from "../../components/ContainerWithPadding"
import successo from "../../../static/assets/images/acquisto-abbonamento.jpg"
import { pushPurchaseEvent, sendPurchaseEvent } from "../../utils"
import { useTranslation } from "react-i18next"

// OBSOLTE: this page is not used anymore
const SubscriptionSuccessPage = () => {
  const { t } = useTranslation()

  return (
    <PageRoot title={t("label:subscriptionSuccess")} isDark={false}>
      <ContainerWithPadding size={"small"}>
        <div className="msg-wrapper">
          <figure>
            <img src={successo} alt="Successo" style={{ maxHeight: 260 }} />
          </figure>
          <h1>{t("message:subscriptionSuccessMessage1")}</h1>
          <p>{t("message:subscriptionSuccessMessage2")}</p>
          <Link to={"/"} className="btn-success">
            <Button>{t("button:accessToSportScience")}</Button>
          </Link>
        </div>
      </ContainerWithPadding>
    </PageRoot>
  )
}

export default SubscriptionSuccessPage
